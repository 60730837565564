.toggle-content-display {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.toggle-content-display+* {
  max-height: 0;
  overflow-y: hidden;
}

.toggle-img {
  margin: 6px 0px;
  pointer-events: none;
}

.forwarded-message-wrapper {
  color: #516c96;
}

.forwarded-message-content {
  display: flex;
  flex-direction: row;
  margin-left: 25px;
  margin-top: 10px;
  color: #4f4f4f;
}

.fowarded-message-metadata {
  color: #7956c3;
}

.sender-email {
  color: #3578db;
}

.vertical-line {
  width: 1px;
  background-color: #dadcde;
  margin-right: 10px;
}
